.switch-wrap {
	background-color: #272727;
	width: 3.5em;
	height: 1.5em;
	border-radius: 0.75em;
	cursor: pointer;
	position: relative;
	transition: background-color 0.3s;
}
.switch-wrap.active {
	background-color: #e8e8e8;
}

.switch {
	position: absolute;
	background-color: #a7a7a7;
	background-image: url('/img/snowflake.svg');
	background-position: center center;
	background-size: 75%;
	background-repeat: no-repeat;
	width: 1.5em;
	height: 1.5em;
	border-radius: 50%;
	margin-left: 0px;
	transform: scale(1.1);

	transition: margin-left 0.3s, transform 0.3s;
}

.switch-wrap.active .switch {
	margin-left: 100%;
	transform: translateX(-100%) scale(1.1);
}