body {
	padding: 0px;
	margin: 0px;
	font-family: 'Mulish', sans-serif;
	color: white;
	/*background: linear-gradient(#000000 0px,#181320 20vh);*/
}

html {
	background: #181320;
}

h1, h2, h3, h4, h5, h6 {
	font-family: 'Bebas Neue', sans-serif;
}

a {
	color: white;
	text-decoration: none;
}
a:hover {
	text-decoration: underline;
}