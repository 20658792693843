.MobileHeader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 8vh;
    z-index: 7;
}

.MobileHeader img {
	height: 4vh;
    margin: 2vh;
}

.BurgerButton {
	background: white;
	display: block;
	position: relative;

	float: right;
	margin-top: calc(5vh - 5px);
	margin-right: 1rem;

	height: 3px;
	width: 5vh;

	transition: all 0.3s;
}
.BurgerButton::before {
	content: '';
	display: block;
	height: 3px;
	width: 5vh;
	background: white;
	position: absolute;
	transform: translateY(-0.7rem);
	transition: all 0.3s;
}
.BurgerButton::after {
	content: '';
	display: block;
	height: 3px;
	width: 5vh;
	background: white;
	position: absolute;
	transform: translateY(0.7rem);
	transition: all 0.3s;
}

.BurgerButton.Opened {
	background: transparent;
	transform: rotate(-70deg);
}
.BurgerButton.Opened::before {
	transform: rotate(70deg) translateX(-0.3rem) rotate(-70deg);
	transform-origin: 50% 50%;
}
.BurgerButton.Opened::after {
	transform: rotate(70deg) translateX(0.3rem) rotate(-70deg);
	transform-origin: 50% 50%;
}

.MobileMenu {
	position: fixed;
    background: linear-gradient(#0D0B12 0px, #0D0B12 100vh, black 101vh, transparent);
    left: 0;
    top: 0px;
    width: 100%;
    height: 109vh;
    z-index: 6;
    -webkit-transform: translateY(calc(-100% - 2px));
    transform: translateY(calc(-100% + 8vh));
    transition: transform 0.3s ease-in-out;
    padding: 0.5rem 1.5rem;
    overflow: hidden auto;
}
.MobileMenu.Opened {
	transform: translateY(0);
}

.MobileMenuContent {
	max-height: calc(100vh - 8vh);
	margin-top: 8vh;
	overflow: hidden auto;
}