.content {
	padding-top: 6rem;
}

.content h1 {
	font-size: 5em;
	font-weight: normal;
}

.content p {
	font-size: 1.3em;
	margin: 20px 0px;
}

.content img {
	width: 100%;
}

@media screen and (max-width: 765px) {
	.content {
		padding-top: 3rem;
	}

	.content p {
		font-size: 1em;
	}

	.content h1 {
		font-size: 3em
	}
}