header {
	position: absolute;
	width: 100%;
}

header img {
	height: 3em;
	margin-left: 3em;
	margin-top: 1em;
	display: inline-block;
    transition: transform 0.3s ease-out;
}