.Navigation {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	display: inline-block;
	padding-top: calc(1rem + 1.4em/2);
	font-size: 1.4em;
	width: fit-content;
}

.Navigation a {
	text-decoration: none;
	margin: 15px;
	color: #a7a7a7;
}

.Navigation a.active, .Navigation a:hover {
	color: white;
	transition: color 0.1s;
}
.Navigation a::before {
	content: '//';
	color: transparent;
	display: inline-block;
	transform: translateX(0.3em);
	transition: all 0.1s;
}
.Navigation a.active::before, .Navigation a:hover::before {
	color: white;
	transform: translateX(0px);
}

/* Mobile navigation */
.Navigation.mobile {
	position: initial;
	transform: none;
	padding-top: 1rem;
	box-sizing: border-box;
}

.Navigation.mobile a {
	display: block;
}