.HomePage {
	background: url('/img/background-min.jpg');
	background-size: max(100vw, 1366px) max(100vh, 768px), 100vw 100vh;
	width: 100vw;
	height: 100vh;
}
.HomePage img {
	height: 11em;
	position: absolute;
	top: 50vh;
	left: 50vw;
	transform: translate(-50%,-100%);
	
	max-width: 80vw;
}
.HomePage p {
	color: white;
	position: absolute;
	font-size: min(2.9em, 5vw);
	margin: 0px;
	margin-top: 0.5em;
	
	top: 50vh;
	text-align: center;
	width: 100%;
}