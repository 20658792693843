.TextLoop {
	/*transform: translateX(-100%);*/
	display: inline-block;
	/*opacity: 0;*/
	transition: all 2s;
	overflow: hidden;
	max-width: 0px;
	white-space: pre;
}
.TextLoop.active {
	transform: none;
	/*opacity: 1;*/
	max-width: 100vw;
}