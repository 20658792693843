.Thumbnail {
	width: 100%;
	overflow: hidden;
}
.Thumbnail img {
	width: 100%;
	border-radius: 5px;
}

.Title {
	font-size: 2.6em;
	margin: 5px 0px 0px 0px;
	font-weight: normal;
}

@media screen and (max-width: 765px) {
	.Title {
		font-size: 1.6em;
	}
}