.button {
	display: block;
	box-sizing: border-box;
	width: 100%;
	border: 1px solid white;
	text-align: center;
	margin: 1em 0px;
	color: white;
	text-decoration: none;
	padding: 10px 25px;
	position: relative;
	transition: color 0.3s;
}

.button:hover {
	color: #181320;
}

.button::before {
    content: '';
    display: block;
    width: 100%;
    height: 0px;
    background: white;
    position: absolute;
    bottom: 0px;
	left: 0px;
	transition: height 0.3s;
	float: left;
	font-size: 1em;
}

.button:hover::before {
	height: 100%;
}